import { useEffect } from 'react';
import { useLocation } from 'react-router-dom';

import { buildRequest, apiRequest, RequestMethod, apiGet, apiPost } from '@utils/http';
import { setByKey, removeByKey } from '@utils/localstorage';

import { IBox } from '@interfaces';
import { ICartBox, ICartBoxLine } from '@interfaces/_cart';
import { IBoxRecord } from '@v2/entities/box.entity';
import { calculateAgeInMonths } from '@v2/components/pages/quiz/utils';
import cloneDeep from 'lodash.clonedeep';

const traitsKey = 'traits';

async function loadTraits() {

    // Comment using caching traits, because we wanna use actial traits each time we call Identify

    // const traits = getByKey(traitsKey);

    // if (traits) {
    //     return traits;
    // } else {
    //     const request = buildRequest(RequestMethod.GET, true);
    //     const response = await apiRequest('user/traits', request, true);
    //     const responseTraits = await response.json();

    //     if (responseTraits.success) {
    //         setByKey(traitsKey, responseTraits.data);
    //         return responseTraits.data;
    //     }
    // }

    const request = buildRequest(RequestMethod.GET, true);
    const response = await apiRequest('user/traits', request, true);
    const responseTraits = await response.json();

    if (responseTraits.success) {
        setByKey(traitsKey, responseTraits.data);
        return responseTraits.data;
    }

    return;
}

interface ITraits {
    userId?: string
    email?:	string
    phoneNumber?: string
    name?: string
    firstName?: string
    lastName?: string
    title?: string
}

// call identify only on login/signup/page load
// we do not need to track anonymous users - https://segment.com/docs/connections/spec/best-practices-identify/
function identify(id: any = undefined, traits: any = {}) {
    if (id) {
        (window as any).analytics.identify(id, traits);
    }
}

// Use this only in case you need to track anonymous users
function identifyTrack(title: string, properties: any = {}, traits: ITraits) {
    (window as any).analytics.identify(
        null,
        traits,
        null,
        () => track(title, properties)
    );
}

function reset() {
    removeByKey(traitsKey);
    (window as any).analytics.reset();
}

// swap anonymousId with new id for example "email"
// Note: use for non logged users
function alias(id: any) {
    if (id) {
        (window as any).analytics.alias(id);
    }
}

function attachEnvToProps(properties: any) {
    if (process.env.ENV !== 'production') {
        properties['testing'] = true;
        properties['environment'] = process.env.ENV;
    }

    return properties;
}

function page(name: string, properties: any = {}) {
    const envProps = attachEnvToProps(properties);
    (window as any).analytics.page(name, envProps);
}

function usePageTrack(name: string, properties: any = {}) {
    const location = useLocation();
    const cbReferrer = location.state ? (location.state as any).referrer : document.referrer;

    useEffect(() => {
        page(name, Object.assign(properties, { cbReferrer }));
    }, []);
}

function track(event: string, properties: any = {}) {
    const envProps = attachEnvToProps(properties);
    (window as any).analytics.track(event, envProps);
}

function selectKeys(object: any, keys?: Array<string>) {
    const newObject: any = {};
    const objKeys = keys ? keys : Object.keys(object);

    objKeys.forEach((key) => {
        newObject[key] = object[key];
    });

    return newObject;
}

function packTrackProps(objects: Array<any>, objectKeys: Array<string | Array<string>>) {
    const trackObjects = objects.map((obj: any, index: number) => {
        const selection = !objectKeys[index] || objectKeys[index] === '*'
                                ? selectKeys(obj)
                                : selectKeys(obj, objectKeys[index] as Array<string>);

        return selection;
    });

    return Object.assign({}, ...trackObjects);
}

function packAndTrack(title: string, objects?: Array<any>, objectKeys?: Array<string | Array<string>>) {
    const trackProps = packTrackProps(objects || [], objectKeys || []);

    track(title, trackProps);
}

function packBox(box: IBox) {
    return packTrackProps([box], [['nochild', 'name', 'gender', 'stageId', 'status']]);
}

function packPersonalizedBox(title: string, box: ICartBox) {
    return packAndTrack(
        title,
        [
            box?.blueprint,
            box?.parent,
            box?.child,
            {surveyUrl: `${process.env.BASE_PATH}shop?childName=${box?.child?.name}&childBirthdate=${box?.child?.birthdate
                            }&childGender=${box?.child?.gender}&packItems=${box?.child?.packItems}&isVegan=${box?.child?.isVegan
                            }&milestoneIds=${(box?.child?.milestoneIds || []).toString()}&firstName=${box?.parent?.firstName
                            }&email=${box?.parent?.email}`},
            {childAge: box?.child?.birthdate ? calculateAgeInMonths(box.child.birthdate) : ''},
            {developmental: box?.stage?.developmental},
            {nutrients: box?.stage?.nutrients ? Object.values(box.stage.nutrients).map(d => d?.icon || null) : {}},
            {products: box?.lines ? Object.values(box.lines).map((d) => {
                return {
                    image: `${process.env.API_BASE}/wp-content/uploads/${d?.product?.featuredImgUrl}`,
                    quantity: d?.count, title:
                    d?.product?.title
                }
            }) : {}},
        ]);
}

function packBoxRecord(box: IBoxRecord) {
    return {
        nochild: !box.childId,
        name: box.title,
        gender: box.child?.gender,
        stageId: box.child?.stageId,
        status: box.status,
    };
}

function packAndTrackBox(title: string, box: IBox, additional?: { [key: string]: any }) {
    packAndTrack(title, [packBox(box), additional || {}]);
}

function packAndTrackBoxRecord(title: string, box: IBoxRecord, additional?: { [key: string]: any }) {
    packAndTrack(title, [packBoxRecord(box), additional || {}]);
}

function trackCartBox(box: ICartBox) {
    const newBox = cloneDeep(box);
    newBox.personalized = newBox.child && newBox.child.name && newBox.child.recommendedPacksEnabled
                        ? true
                        : false;
    newBox.size = newBox.blueprint.type;

    // Facebook - recognized properties
    newBox.value = newBox.price;
    newBox.currency = 'USD';
    newBox.num_items = box.count;
    newBox.contents = Object.values(newBox.lines).map(line => ({
        id: (line as ICartBoxLine).product.sku,
        quantity: (line as ICartBoxLine).count,
        name: (line as ICartBoxLine).product.title,
        image: `${process.env.API_BASE}wp-json/cerebelly/image/get?path=${(line as ICartBoxLine).product.mainImage}`
    } as any))

    packAndTrack(
        'Add To Cart Clicked',
        [newBox],
        [['count', 'price', 'personalized', 'size', 'value', 'currency', 'num_items', 'contents']]);
}

async function location() {
    return apiGet('frontend/location', true)
            .then((response: any) => response as any)
            .catch(err => console.error('Failed to load User Location data', err));
}

async function subscribe_canada(email: string) {
    return apiPost('frontend/subscribe-canada', {email: email})
            .then((response: any) => response as any)
            .catch(err => console.error(`Failed to subscribe ${email} to canada newsletter email`, err));
}

export {
    loadTraits,
    identify,
    identifyTrack,
    reset,
    alias,
    page,
    usePageTrack,
    track,
    packAndTrack,
    packAndTrackBox,
    packAndTrackBoxRecord,
    trackCartBox,
    location,
    subscribe_canada,
    packPersonalizedBox
}
